<template>
  <div>
    <keep-alive include="AdminNewsFeedList">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
export default {};
</script>
